export const strichpunkt = {
    title: "Strichpunkt, <br />Strich, Punkt,",
    subTitle: "die kleinste Einheit <br />des Malens.",
    background: "#938E94",
    arrow: "/assets/gestaltungselemente/akkordeon-arrow-grey.svg",
    items: [
        {
            title: "Ursprung",
            content: "Der Strichpunkt hat eine bewegte Geschichte hinter sich. Im Frühhochdeutschen wurde er Mittelzeichen, Periodus oder Periodus minor genannt, später im Neuhochdeutschen Strichpünklein, Beistrichlein, punktiertes Strichlein, Commapunkt oder Semicomma, aber auch schon Strichpunkt. "
        },
        {
            title: "Funktion",
            content: "Bereits früh dienten Satzzeichen zur Strukturierung des Textes und zur besseren Lesbarkeit; sie zeigen einen gewünschten Sprech- und Atemrhythmus an. Später bezweckten sie, Klarheit in die Syntax (altgiechisch: syn zusammen; taxis Ordnung Reihenfolge) zu bringen; die vielen Wörter liessen sich mit der Zeichensetzung leichter bewältigen. Laut Duden ist der Strichpunkt ein Verbindungszeichen von zwei gleichwertigen Sätzen, Satzteilen oder Wortgruppen"
        },
        {
            title: "Neuzeit",
            content: "Semikolon findet in Texten nicht mehr viel Anwendung, denn es besteht eine gewisse Unsicherheit im Gebrauch. Das Ausrufezeichen, der Punkt und das Komma werden bedeutend öfter eingesetzt. Dabei ist der Strichpunkt äusserst nützlich, wird mit ihm bewusst das Dazwischen gewählt, einen Zwischenraum, einen Freiraum. "
        },
        {
            title: "Freiräume schaffen",
            content: "Coaching und Kunsttherapie stellen einen Zwischenraum dar, indem neu geordnet und strukturiert werden kann. Es ist ein Raum, der vielleicht mit einer gewissen Unsicherheit betreten wird; oft werden dort Ressourcen entdeckt, Verbindungen geschaffen und Kommunikation bzw. Handlung aktiviert und erprobt."
        },
    ]
}

export const natur = {
    title: "Die Natur<br /> und ihre Bedeutung",
    subTitle: "",
    background: "#fff",
    arrow: "/assets/gestaltungselemente/akkordeon-arrow-red.svg",
    items: [
        {
            title: "Hintergrund",
            content: "Vor einigen Jahrmillionen war der Mensch bzw. seine Frühformen Teil der Natur. Er hat sich aus dieser Einheit gelöst und von der Natur entfernt. In seiner Biophilie-Hypothese hat Wilson (1984) postuliert, dass die Tendenz, Kontakt zu Pflanzen und Tieren zu suchen, dem Menschen angeboren sei.  Dabei sei nicht nur die Nutzung von Tieren und Pflanzen gemeint, sondern auch, dass die Natur einen Einfluss auf die «emotionale, kognitive, ästhetische und spirituelle Entwicklung» (Jordan 2015, zit. in Knümann 2019, S. 50) des Menschen habe, was auf die Evolution des menschlichen Gehirns zurückgeführt werden kann, da es sich mehrere Millionen von Jahren anhand der Gegebenheiten der Natur entwickelt hat. Erst in den letzten Jahrhunderten verbrachte ein Teil der Menschheit sein Leben in dicht besiedelteren bzw. dicht bebauteren Gebieten. Der Grossteil der Menschen hat seine Zeit «in der Natur verbracht und dabei seinen Organismus mit allen physiologischen Funktionen an die natürliche Umgebung angepasst und optimiert (Miyazaki 2018, S. 26).  Die Biophilie-Hypothese behauptet, dass wir uns daher noch heute in der Natur wohl fühlen. "
        },
        {
            title: "Bedeutung und Einfluss der Gartenarbeit",
            content: "Als sich die Nomaden zur Sesshaftigkeit entwickelten, wurden Pflanzen nicht nur in der Natur gesammelt, sondern auch an einem Ort angesät, vermehrt und sie haben mit den Jahren ertragreichere Sorten hervorgebracht. Der Mensch hat versucht, die Natur nach individuellen Wünschen und Bedürfnissen anzupassen, was heute noch in seiner Beziehung zum Garten oder im weiteren Sinne zu sich selber zum Ausdruck kommt.<br /><br /> Es gibt diverse Studien und Berichte über die positive Wirkung des Gartens. Haubenhofer beschreibt in ihrem Bericht (2019, S. 168/169), dass die kreative Tätigkeit im Garten, innert kürzerer Zeit sichtbare Erfolge, die Selbstwirksamkeit und -verwirklichung, aber auch das Wachstum, die Fürsorge, den Rückzug und Produktivität als begünstigende Faktoren auf. Im Weitern wird das Spiegeln der eigenen Identität erwähnt, was für die Persönlichkeitsentwicklung unterstützend ist.<br /><br /> Die Natur und insbesondere Pflanzen rufen oft vielfältige Sinneseindrücke und Reaktionen in den Menschen hervor, denn sie wirken durch ihr Erscheinungsbild, durch ihren Duft und Geschmack, durch ihre Geräusche und ihre Haptik auf die Sinne. Durch die Auseinandersetzung mit der Natur wird ein Gefühl von täglicher Regelmässigkeit und Jahresrhythmus unterstützt, aber auch die Befriedigung des Bedürfnisses nach Zuwendung und Zugehörigkeit bzw. nach Interaktion (vgl. diverse Rewiews und Publikationen u.a. Williams, Dixon (2013), Draper & Freedman (2010), Guitart et al. (2012), Berezowitz et al. (2015), Haubenhofer et al. (2016), alle erwähnt in Haubenhofer 2019)."
        },
        {
            title: "Einfluss der Natur auf emotionale und mentale Ebene",
            content: "Theorien und Forschungen von Kaplan und Kaplan (1989), Ulrich et al. (1991), Flade (2010) und Arvay (2015) zeigen und erklären mögliche Zusammenhänge vom Aufenthalt oder der Bewegung in der Natur und Erholung bzw. Stressreduktion und der Milderung von negativen psychischen Zuständen auf. In Zwischenzeit gibt es zahlreiche Studien, die belegen, dass der Mensch sich in der Natur besonders wohl fühlt.<br /><br />Der Aufenthalt in der Natur kann in den Menschen in der Evolution entwickelte Verhaltensmuster wachrufen und eine erholsame Wirkung haben – Entspannung und ein angeregter Körper können die Folge sein. Die psychische Ebene wird von der Natur beeinflusst, wobei die emotionalen und mentalen Wirkungen wissenschaftlich belegt wurden, so dass u.a. Angst bei einer Stärkung der Naturbezogenheit sich verringern (Gans et al. 2020, S. 32), durch Aufenthalt und Bewegung in der Natur können Symptome einer Depression vorgebeugt oder gelindert (Shanahan et al. 2016), Stress und Grübeln verringert werden (Flade 2010, S. 86 / Miyazaki 2018, S. 144 / Bratman et al. 2015). <br />Das Selbstwertgefühl und die Aufmerksamkeit können durch körperliche Aktivität in der Natur gesteigert werden (Barton und Pretty 2010 / Berman et al. 2008), ebenso kann sich die Stimmung dadurch heben (Passmore und Holder 2017)."
        },
        {
            title: "Einfluss der Natur auf den Körper",
            content: "Nicht nur die emotionale und mentale Ebene wird durch die Natur beeinflusst, sie hat positive Auswirkungen auf den physischen Zustand. Das Wetter und die terpenhaltige Waldluft stärken das menschliche Immunsystem (Schuh 2004, S. 25 / Arvay 2015, S. 20), der Aufenthalt im Wald kann zusätzlich Cortisol (ein Stresshormon) reduzieren helfen (Miyazaki 2018, S. 130). Zusätzlich hat die Bewegung an der frischen Luft einen positiven Einfluss auf Hirnaktivität und die Gesundheit im Allgemeinen (vgl. Hume 2020, S. 37 – 41). "
        },
        {
            title: "Naturraum",
            content: "Seit Milliarden von Jahren besteht die Natur fort und entwickelt sich. Sie bietet Analogien zum menschlichen Leben an, die durch Beobachtung entdeckt, abstrahiert und auf das eigene Leben übertragen werden können. Im Jahreslauf verändern sich Landschaften und die Natur, Pflanzen spriessen im Frühling, stehen in voller Blüte im Sommer, im Herbst tragen sie Früchte aus denen sich ein Samen entwickelt, der wieder neues Wachstum anstösst, wenn der Winter vorüber ist. So gesehen, hält Entwicklung einen bestimmten Ablauf ein. Dieses Bild kann auf eine gewisse Lebenssituation übertragen als Metapher dienen oder es bietet Symbole an, die für eine bestimmte Bedeutung stehen, die zusammen mit der Berater*in oder Therapeut*in bearbeitet bzw. erweitert werden können."
        },
    ]
}