import React from "react"

import DefaultLayout from "../layouts/default"
import { natur } from "../data/akkordeon"

const active = {
  title: "Natur und ihre Bedeutung"
}

const NaturUndIhreBedeutung = () =>
  <div style={{ paddingTop: 70 }}>
    <DefaultLayout image="/assets/images/IMG_6986.jpg" active={active} akkordeon={natur}>

    </DefaultLayout>
  </div>

export default NaturUndIhreBedeutung